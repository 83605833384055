var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "panel-wrapper email-list-panel " },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-subheader", { staticClass: "panel-title fs-1 pl-4" }, [
                _c("span", { staticStyle: { color: "rgba(0,0,0,.87)" } }, [
                  _vm._v("Email List")
                ])
              ])
            ],
            1
          ),
          _vm.list && _vm.list.length
            ? [
                _c(
                  "v-flex",
                  [
                    _c(
                      "v-expansion-panel",
                      {
                        staticClass: "email-expansion-panel",
                        attrs: { popout: "" },
                        model: {
                          value: _vm.expansions,
                          callback: function($$v) {
                            _vm.expansions = $$v
                          },
                          expression: "expansions"
                        }
                      },
                      _vm._l(_vm.list, function(item, i) {
                        return _c(
                          "v-expansion-panel-content",
                          {
                            key: i,
                            ref: "panels",
                            refInFor: true,
                            staticClass: "email-expansion-content",
                            class: { hasRead: item.is_read },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-layout",
                                        { staticStyle: { height: "90px" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticStyle: { width: "90px" },
                                              attrs: { shrink: "" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "h-image" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "image-wrapper"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            size: "24",
                                                            color: "#fff"
                                                          }
                                                        },
                                                        [_vm._v("email")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticStyle: {
                                                "max-width": "80%"
                                              },
                                              attrs: { grow: "" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "h-content " },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "h-title t-o",
                                                      staticStyle: {
                                                        "font-size": "14px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.subject)
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "h-title-2 t-o",
                                                      staticStyle: {
                                                        "line-height": "40px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#999"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "formatDate"
                                                              )(item.created)
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "    \n                                            "
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.email_sender_label
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-text", { staticClass: "py-0" }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "padding-left": "74px",
                                        "padding-right": "24px"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("div", {
                                            staticClass: "pa-4 email-body",
                                            domProps: {
                                              innerHTML: _vm._s(item.html)
                                            }
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "mt-3 mb-4 text-none",
                                              staticStyle: {
                                                "margin-left": "0"
                                              },
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$refs.newticket.open(
                                                    {
                                                      department: 1,
                                                      subject:
                                                        "Re : " + item.subject
                                                    }
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Replay")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { "align-self-center": "" } },
                  [
                    _vm.list.length < _vm.total
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "text-none ml-4",
                            attrs: {
                              color: "primary",
                              outline: "",
                              loading: _vm.loading
                            },
                            on: { click: _vm.morePage }
                          },
                          [_vm._v("View More")]
                        )
                      : _vm._e(),
                    _vm.list.length >= _vm.total
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "text-none ml-4",
                            attrs: {
                              color: "primary",
                              outline: "",
                              disabled: ""
                            }
                          },
                          [_vm._v("No More")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.list && !_vm.list.length
            ? [
                _c(
                  "div",
                  {
                    staticStyle: { "min-height": "300px", position: "relative" }
                  },
                  [_c("Empty")],
                  1
                )
              ]
            : _vm._e(),
          _vm.loading && !_vm.list
            ? [
                _c(
                  "div",
                  {
                    staticStyle: { "min-height": "300px", position: "relative" }
                  },
                  [_c("Spin")],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _c("NewTicket", { ref: "newticket", on: { confirm: _vm.createTicket } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }