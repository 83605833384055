<template>
    <v-container class="panel-wrapper email-list-panel ">
        <v-layout column>
            <v-flex>
                <v-subheader class="panel-title fs-1 pl-4"><span style="color: rgba(0,0,0,.87)" >Email List</span></v-subheader>
            </v-flex>
            <template v-if="list && list.length">
                <v-flex>
                    <v-expansion-panel  popout class="email-expansion-panel" v-model="expansions" >
                        <v-expansion-panel-content ref="panels" v-for="(item,i) in list" :key="i" class="email-expansion-content" :class="{hasRead:item.is_read}">
                            <template v-slot:header>
                                <v-layout style="height: 90px">
                                    <v-flex shrink style="width: 90px">
                                        <div class="h-image" >
                                           <span class="image-wrapper">
                                                <v-icon size="24" color="#fff">email</v-icon>
                                            </span>
                                        </div>
                                    </v-flex>
                                    <v-flex  grow style="max-width: 80%">
                                        <div class="h-content ">
                                            <p class="h-title t-o" style="font-size: 14px">{{item.subject}}</p>
                                            <p  class="h-title-2 t-o" style="line-height: 40px">
                                                <span style="color:#999;">{{item.created | formatDate}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <span>{{item.email_sender_label}}</span>
                                            </p>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </template>
                            <v-card>
                                <v-card-text class="py-0">
                                    <div style="padding-left: 74px;padding-right: 24px">
                                        <div>
                                            <div class="pa-4 email-body"  v-html="item.html"></div>
                                            <v-btn color="primary" @click="$refs.newticket.open({department :1,subject: `Re : ${item.subject}`})" class="mt-3 mb-4 text-none" style="margin-left: 0">Replay</v-btn>
                                        </div>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-flex>
                <v-flex align-self-center>
                    <v-btn v-if="list.length<total"  @click="morePage" class="text-none ml-4" color="primary" outline :loading="loading">View More</v-btn>
                    <v-btn v-if="list.length>=total" class="text-none ml-4" color="primary" outline disabled>No More</v-btn>
                </v-flex>
            </template>
            <template v-if="list && !list.length">
                <div style="min-height: 300px;position: relative;">
                    <Empty></Empty>
                </div>
            </template>
            <template v-if="loading && !list">
                <div style="min-height: 300px;position: relative;">
                    <Spin></Spin>
                </div>
            </template>
        </v-layout>
        <NewTicket ref="newticket" @confirm="createTicket"></NewTicket>
    </v-container>
</template>

<script>
    import Empty from '@/components/Empty'
    import Spin from '@/components/Spin'
    import NewTicket from '../tickets/_source/NewTicket'
    import $ from 'jquery'

    export default {
        name: "Email Announcement",
        components: {Empty, Spin ,NewTicket},
        data() {
            return {
                loading: false,
                expansions:null,
                page_size: 20,
                assist_data: null, //unread
                total:0,
                list: null,
            }
        },
        watch:{
            expansions(v){
                if(typeof v === 'number'){
                    let item = this.list[v]
                    let $item = $(this.$refs.panels[v].$el)
                    setTimeout(() => {
                        this.$vuetify.goTo($item.offset().top-20)
                    },300)
                    if(!item.is_read){
                        this.changeRead(item)
                    }
                }
            }
        },
        methods: {
            createTicket(formData) {
                return this.$http.post('/tickets/',formData).then((res) => {
                    this.$message.success(`Your ticket ID ${res.number} has been submitted successfully. Our support staff will get back to you within 2 hours. Thanks for your patience.`)
                }).catch((e) => {
                    this.$message.error(e.detail)
                })
            },
            changeRead(item){
                this.$http.post(`/emails/${item.id}/read/`).then(() => {
                    item.is_read = true
                    this.assist_data.unread = this.assist_data.unread -1
                    if(this.assist_data.unread <= 0){
                        this.$root.$emit('closeEmailFlag',false)
                    }
                }).catch(e => {
                    this.$message.error(e.detail)
                })
            },
            getList(id) {
                this.loading = true
                let params = {
                    page_size: 20
                }
                if(id){
                    params.id__lt  = id
                }
                this.$http.get('/emails/', {
                    params
                }).then(({total,results,assist_data}) => {
                    this.loading = false
                    this.assist_data = assist_data
                    this.total = total
                    this.list = this.list?this.list:[]
                    this.list = this.list.concat(results)
                    // if(assist_data.unread > 0){
                    //     this.$root.$emit('closeEmailFlag',true)
                    // }
                }).catch(e => {
                    this.loading = false
                    this.$message.error(e.detail)
                })
            },
            morePage() {
                let id = this.list[this.list.length-1].id
                this.getList(id)
            }
        },
        created() {
            this.getList()
        }
    }
</script>
<style lang="scss">
    .email-list-panel {
        .t-o{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .email-expansion-panel{
            .email-expansion-content{
                margin-bottom: 10px;
                border-top-width: 0 ;
                .v-expansion-panel__header{
                    padding: 0 10px 0 0;
                    .v-expansion-panel__header__icon i{
                        font-size: 30px;
                        color:#4d5eba !important;
                    }
                    .h-image{
                        display: flex;
                        height:100%;
                        width: 90px;
                        justify-content: center;
                        align-items: center;
                        .image-wrapper{
                            padding: 8px;
                            background-color: #4d5eba;
                            border-radius: 6px;
                            position: relative;
                            top: -6px;
                        }
                    }
                    .h-content{
                        padding: 13px 0;
                        font-size: 16px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        p{
                            margin-bottom:0;
                            &.h-title{
                                font-size: 16px !important;
                                color:#4d5eba ;
                            }
                        }
                    }
                }
                .email-body{
                    background-color: #eee;
                    border-radius: 6px;
                }
                &.v-expansion-panel__container--active{
                    margin-top: inherit;
                }
                &.hasRead{
                    .v-expansion-panel__header{
                        .h-image .image-wrapper{
                            background-color: #999;
                        }
                        .h-content .h-title,.h-content .h-title-2 span{
                            color:#999;
                        }
                    }
                }
            }
        }
    }
</style>

